<template>
  <div
    class="mall-container"
    id="indexBar"
  >
    <div class="order-menu-index">
      <!-- 轮播图 -->
      <van-swipe
        height="9.3125rem"
        ref="banner"
        :autoplay="5000"
      >
        <van-swipe-item
          v-for="(item,index) in banner"
          :key="index"
        >
          <div
            class="banner row-center"
            @click="goDetails(item,'recommend')"
            :style="'width: 94%;height: 9.3125rem;margin: 0.9375rem 3% 0 3%'"
          >
            <img
              :src="item.image"
              style="border-radius: .5rem;width: 100%;height: auto;"
            />
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- tab -->
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        :class="swipeIsShow1 ? '' : 'el-index-menu-top'"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item
          index="1"
          class="el-menu-demo-item"
        >
          热卖藏品
          <img
            v-if="activeIndex == 1"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <!-- <el-menu-item index="2">
          发售日历
          <img
            v-if="activeIndex == 2"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item> -->
        <el-menu-item index="3">
          盲盒
          <img
            v-if="activeIndex == 3"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
      </el-menu>
    </div>

    <!-- 商品列表 -->
    <div
      v-if="activeIndex==1"
      class="swiper-container"
      style="padding-top: 10px;"
    >
      <swiper
        class="my-swipe"
        ref="banner"
        :options="swiperOption"
      >
        <swiper-slide
          class="good-card"
          v-for="(item,index) in goods"
          :key="index"
          @click="goDetails(item,'buy')"
        >
          <div
            class="row-center row-center-img"
            :style="'width: 100%;height: 94vw;background-color: #D0D0D0;'"
          >
            <div class="row-center-img-inline">
              <img
                style=""
                :src="item.image"
                alt=""
              >
            </div>
          </div>
          <div class="good-card-bg">
            <p class="good-name"> {{item.storeName}} </p>
            <div
              style="margin-top: 10px;flex-wrap: wrap;"
              class="row-start"
            >
              <limit-item
                style="margin-bottom: 10px;"
                v-if="item.limitInfo"
                limitTitle="限量"
                :limitContent="item.limitInfo"
              ></limit-item>
              <limit-item
                style="margin-bottom: 10px;"
                v-for="(limitItem,limitIndex) in item.customTags"
                :key="limitIndex"
                :limitTitle="limitItem.field2 ? limitItem.field1 : ''"
                :limitContent="limitItem.field2 ? limitItem.field2 : limitItem.field1"
              ></limit-item>
              <img
                style="margin-bottom: 10px;"
                class="good-detail-type"
                :src="mediaType[item.cateId] == 'img' ? require('../assets/icon/media/good-img.png') : mediaType[item.cateId] == 'audio' ?  require('../assets/icon/media/good-audio.png'): mediaType[item.cateId] == 'video' ?  require('../assets/icon/media/good-video.png') :  require('../assets/icon/media/good-3d.png')"
                alt=""
              >
            </div>

            <div class="row-between">
              <div class="row-start">
                <div :style="'width: 1.875rem;height: 1.875rem;background-image: url('+ item.userPic +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% 0%;background-size: 100% auto;background-color: transparent;border-radius: 50%;'"></div>
                <p class="author-name">{{item.userName}}</p>
              </div>
              <p class="good-prise">
                <span style="font-size: 1.625rem;margin-right:0.625rem;">¥</span>
                <span style="font-size: 2.1875rem;">{{ String(item.price).split('.')[0].trim()}}{{ String(item.price).split('.').length == 2 ? '.' : ''}}</span>
                <span
                  v-if="String(item.price).split('.').length == 2"
                  style="font-size: 1.625rem;"
                >{{String(item.price).split('.')[1]}}</span>
              </p>
            </div>

          </div>

          <div
            v-if="item.isOpen == 1 && (item.stock == 0 || item.sellStatus == '已售罄' || item.sellStatus == '售完' || item.sellStatus == '即将开放' || ((new Date(item.endTime.replace(/-/g,'/'))).getTime() < (new Date()).getTime()) || ((new Date(item.startTime.replace(/-/g,'/'))).getTime() > (new Date()).getTime()))"
            class="order-sales-status row-center"
            style="background: #000000;border-radius: 16px;opacity: 0.73;"
          >
            <img
              class="index-icon"
              :src="((new Date(item.endTime.replace(/-/g,'/'))).getTime() < (new Date()).getTime()) || item.stock == 0 || item.sellStatus == '已售罄' || item.sellStatus == '售完' ? require('../assets/icon/no_goods.png') : require('../assets/icon/time_limit.png')"
            />
            <p class="index-time-tip">{{ (item.endTime && (((new Date()).getTime()) > (new Date(item.endTime.replace(/-/g,'/'))).getTime())) && item.stock != 0 ? '售卖结束' : item.stock == 0 || item.sellStatus == '已售罄' || item.sellStatus == '售完' ? '已售罄' : item.startTime ? item.startTime.substring(5,item.startTime.length - 3) + ' 开放' : '即将开放' }}</p>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 系列商品 -->
      <div
        class="order-menu-index"
        style="width: 100vw;margin-left: 0vw;"
        v-if="collectList.length>0"
      >
        <el-menu
          :default-active="collectionActiveIndex"
          style="margin-bottom: 10px;width: 100vw;justify-content: space-between;"
          class="el-menu-demo"
          :class="swipeIsShow2 ? '' : 'el-index-menu-top'"
          mode="horizontal"
        >
          <el-menu-item
            index="1"
            class="el-menu-item1"
            style="margin-left: 3vw;"
            @click="$router.push('/seriesCollection')"
          >
            系列藏品
            <img
              v-if="collectionActiveIndex == 1"
              src="../assets/icon/list-select.png"
            />
          </el-menu-item>
          <el-menu-item
            v-if="collectList.length>1"
            index="2"
            style="color: #F3CDB6 !important;margin-right:3vw;"
            class="el-menu-item2"
            @click="$router.push('/seriesCollection')"
          >
            查看更多
          </el-menu-item>
        </el-menu>
      </div>
      <swiper
        class="my-swipe"
        ref="banner"
        :options="seriesOption"
      >
        <swiper-slide
          class="good-card"
          v-for="(item,index) in selectCollectList"
          :key="index"
        >
          <div
            @click="goseriesDetails(item)"
            class="row-center row-center-img"
            :style="'width: 100%;height: 94vw;background-color: #D0D0D0;'"
          >
            <div class="row-center-img-inline">
              <img
                style=""
                :src="item.image"
                alt=""
              >
            </div>
          </div>
          <div
            @click="goseriesDetails(item)"
            class="good-card-bg"
          >
            <p class="good-name"> {{item.name}} </p>
            <div
              style="margin-top: 10px;"
              class="row-start"
            >
              <limit-item
                v-if="item.drawFlag != 0"
                limitTitle=""
                :limitContent="item.drawFlag == 1 ? '抽签购' :''"
              ></limit-item>
              <!-- <img
                style="margin-bottom: 6px;"
                class="good-detail-type"
                :src="mediaType[item.cateId] == 'img' ? require('../assets/icon/media/good-img.png') : mediaType[item.cateId] == 'audio' ?  require('../assets/icon/media/good-audio.png'): mediaType[item.cateId] == 'video' ?  require('../assets/icon/media/good-video.png') :  require('../assets/icon/media/good-3d.png')"
                alt=""
              > -->
            </div>

            <div
              class="row-left"
              style="margin-top: 12px;"
            >
              <div :style="'margin-right:9px;width: 23px;height: 23px;background-image: url('+ item.creatorAvatar +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% 0%;background-size: 100% auto;background-color: transparent;border-radius: 50%;'"></div>
              <p class="author-name">{{item.creatorName}}</p>
            </div>
            <div
              class="buy-now row-center good-detail-buy-bottom"
              @click="goseriesDetails(item)"
            >立即查看</div>
          </div>

          <!-- <div
            v-if="item.showFlag==1"
            class="order-sales-status row-center"
            style="background: #000000;border-radius: 16px;opacity: 0.73;"
          >
            <img
              class="index-icon"
              :src="require('../assets/icon/time_limit.png')"
            />
            <p class="index-time-tip">售卖中</p>
          </div> -->
        </swiper-slide>
      </swiper>
      <!-- 馆藏商品 -->
      <div class="order-menu-index">
        <el-menu
          :default-active="collectionActiveIndex"
          class="el-menu-demo row-start"
          mode="horizontal"
        >
          <el-menu-item
            index="1"
            class="el-menu-item1"
          >
            推荐藏品
            <img
              v-if="collectionActiveIndex == 1"
              src="../assets/icon/list-select.png"
            />
          </el-menu-item>
        </el-menu>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="load"
        :immediate-check="false"
      >
        <div class="collection-list">
          <collection-item
            v-for="item in collectionList"
            :key="item.id"
            :collectionInfo="item"
          >
          </collection-item>
        </div>

      </van-list>
    </div>
    <div
      v-if="activeIndex==2"
      style="padding-top:10px;"
    >
      <div
        class="order-item"
        v-for="(val, key, index) in calendarList"
        :key="index"
      >
        <div class="date-type">
          <p class="date-type-tip">{{ key }}</p>
        </div>
        <div
          class="order-content "
          v-for="(valule, k, ind) in val"
          :key="ind"
        >
          <div class="date-type-card">
            <p class="date-type-tip">{{ k }}</p>
          </div>
          <div
            class="date-img"
            v-for="(item,i) in valule"
            :key="i"
          >
            <div
              class="order-img-name-img"
              :style="'width: 128px;height: 128px;background-color: transparent;border-radius: 4px;'"
            >
              <div class="order-img-name-img-inline">
                <img
                  style="border-radius: 4px;"
                  :src="item.img"
                  alt=""
                >
              </div>
            </div>
            <div style="padding: 1.05rem 0 0 1.25rem;color:#fff;font-size: 1.025rem;font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;display: flex;
  flex-direction: column;
  justify-content: space-around;">
              <p class="order-amount">{{item.content}}</p>
              <limit-item
                v-if="item.limitCount"
                limitTitle="限量"
                :limitContent="String(item.limitCount)"
              ></limit-item>
              <span style="font-size: 1.76rem;">¥{{ item.price }}</span>
            </div>
          </div>

        </div>
      </div>
      <div
        v-if="calendarListNum==0"
        class="col-center"
        style="width:100%;margin-top:10vh;"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;margin-bottom: 5vh;">暂无数据</p>
      </div>
    </div>
    <div
      v-if="activeIndex==3"
      style="padding-top:10px;"
    >
      <van-list
        v-model="loading1"
        :finished="finished1"
        finished-text="没有更多了"
        @load="load1"
      >
        <div class="collection-list">
          <collection-item
            v-for="item in blindBoxList"
            :key="item.id"
            :collectionInfo="item"
          >
          </collection-item>
        </div>

      </van-list>
      <div
        v-if="blindBoxList.length==0"
        class="col-center"
        style="width:100%;margin-top:10vh;"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;margin-bottom: 5vh;">暂无数据</p>
      </div>
    </div>
    <el-dialog
      title="风险提示"
      :visible.sync="riskConfirm"
      class="log-off-confirm-dialog"
      center
      style="margin-top: 15vh;"
      :show-close='true'
    >
      <p
        style="margin-top:10px;"
        class="log-off-tip3"
      >数字藏品为虚拟数字商品，并非实物。请勿对数字藏品进行炒作、场外交易或任何非法方式进行使用。</p>
      <span
        slot="footer"
        style="margin-top:2rem;z-index:9999"
        class="dialog-footer row-center"
      >

        <div
          @click="riskcancel"
          class="confirm-button row-center"
        >我已知晓</div>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import CollectionItem from '../components/CollectionItem.vue'
import api from '../api/index-client'
import { Swipe,SwipeItem,List } from "vant";
import Vue from 'vue';
import { Menu,MenuItem,InfiniteScroll } from 'element-ui';
import LimitItem from '../components/LimitItem.vue'
import Cookies from 'js-cookie'
Vue.use(List);
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(InfiniteScroll)
import config from '../config'
var vm = null
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    LimitItem,
    CollectionItem
  },
  data () {
    return {
      riskConfirm: false,
      loading1: false,
      finished1: false,
      collectionActiveIndex: '1',
      swipeIsShow1: true,
      swipeIsShow2: true,
      swiperOption: {
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        slidesPerView: 'auto',
        clicks: {
          preventClicksPropagation: false,
        },
        centeredSlides: true,
        spaceBetween: 7,
        loopAdditionalSlides: 100,
        on: {
          click () {
            vm.goswiperDetails(this)
          }
        }
      },
      seriesOption: {
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        slidesPerView: 'auto',
        clicks: {
          preventClicksPropagation: false,
        },
        centeredSlides: true,
        spaceBetween: 7,
        loopAdditionalSlides: 100,
        on: {
          // click() {
          //  vm.goseriesDetails(this)
          // }
        }
      },
      recommendList: [
      ],
      calendarList: {
      },
      collectionList: [],
      collectList: [],
      selectCollectList: [],
      activeIndex: '1',
      loading: false,
      finished: false,
      banner: [],
      goodsParam: {
        page: 1,
        limit: 12,
      },
      calendarGoodsParam: {
        page: 0,
        limit: 12,
      },
      indexLoad: true,
      indexLoad1: true,
      blindBoxParam: {
        page: 0,
        limit: 12,
      },
      blindBoxList: [],
      goods: [],
      calendarListNum: 0,
      aspectRatio: true,
      mediaType: config.mediaType,
      scrollHeigh: '',
      indexGoodMore: false,
      indexGoodMore1: false,
    }
  },
  created () {
    vm = this
    this.getGoods()
    this.getcollectList()
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
    window.addEventListener('scroll',this.throttle(this.handleScroll))
    if (window.location.hash.indexOf('spread') != -1) {
      localStorage.setItem('spread',window.location.hash.split('spread=')[1],{ expires: 1 })
    }
    if (this.$route.query.spread) {
      localStorage.setItem('spread',this.$route.query.spread,{ expires: 1 })
    }
    if (this.$route.query.inviteCode) {
      localStorage.setItem('inviteCode',this.$route.query.inviteCode,{ expires: 1 })
    }
    this.goods = []
    this.collectionList = []
    this.getBanner() // 获取banner
    if (Cookies.get('Login')) {
      this.riskConfirm = Cookies.get('riskConfirm') == 'true' ? true : false
      this.getUserInfo() // 获取用户状态
    }
  },
  methods: {
    riskcancel () {
      this.riskConfirm = false;
      Cookies.set('riskConfirm',false)
    },
    throttle (fn) {
      let temp = false;
      return function () {
        if (temp) {
          return;
        } else {
          temp = true;
          setTimeout(() => {
            fn.apply(this,arguments);
            temp = false;
          },100);
        }
      }
    },
    handleScroll (e) { //改变元素#searchBar的top值
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let scrollTop1 = document.documentElement.scrollTop || document.body.scrollTop;
      // 变量 windowHeight 是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 变量 scrollHeight 是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      var scrollBottom = scrollHeight - windowHeight - scrollTop1
      if (scrollBottom <= 100 && this.activeIndex == 1) {
        setTimeout(() => {
          this.indexGoodMore = true
          this.load()
        },500)
      }
      if (scrollBottom <= 100 && this.activeIndex == 3) {
        setTimeout(() => {
          this.indexGoodMore1 = true
          this.load1()
        },500)

      }
      if (scrollTop <= 170) {
        this.swipeIsShow1 = true
        this.swipeIsShow2 = true
      } else if (scrollTop <= 660) {
        this.swipeIsShow1 = false
        this.swipeIsShow2 = true
      } else {
        this.swipeIsShow1 = true
        this.swipeIsShow2 = false
      }
    },
    //发售日历
    getCalendar () {
      api
        .get('calendar/list')
        .then(result => {
          if (result.data.success) {
            this.calendarList = result.data.data.content
            this.calendarListNum = result.data.data.totalElements
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // tab切换
    handleSelect (key,keyPath) {
      localStorage.setItem('orderIndex',key)
      this.goodsParam.page = 1
      this.loading = true
      this.orderList = []
      this.activeIndex = key
      if (key == 2) {
        this.getCalendar()
      }
      if (key == 3) {
        this.indexGoodMore1 = true
        this.load1()
      }

    },
    getUserInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            if (result.data.data.openId) {
              this.$store.commit('SET_USEROPENID',result.data.data.openId)
            }
            if (result.data.data.hasRealname) {
              this.$store.commit('HIDE_ISAUTH')
            } else {
              this.$store.commit('SHOW_ISAUTH')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取首页banner数据
    getBanner () {
      api
        .get('cms/list',{ code: 'banner' })
        .then(result => {
          if (result.data.success) {
            this.banner = []
            result.data.data.map((i,j) => {
              if (j < 5) {
                let img = new Image()
                img.src = i.image
                i.aspectRatio = (img.width / img.height) <= 2.5 ? false : true
                this.banner.push(i)
              }

            })

          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取热卖藏品
    getGoods () {
      api
        .get('goods/recommend?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
        .then(result => {
          if (result.data.success) {
            result.data.data.map(i => {
              this.goods.push(i)
            })
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.loading = false
          this.$store.commit("HIDE_APPLOADING")
        })
    },
    // 获取推荐藏品
    getcollectionList (i) {
      this.indexLoad = false
      api
        .get('goods?page=' + i + '&limit=' + this.calendarGoodsParam.limit + '&type=5')
        .then(result => {
          if (result.data.success) {
            result.data.data.map(i => {
              this.collectionList.push({
                id: i.id,
                image: i.image,
                name: i.storeName,
                exclusiveBuyType: i.exclusiveBuyType,
                limitInfo: i.limitInfo,
                price: i.price,
                cateId: i.cateId,
                isOpen: i.isOpen,
                stock: i.stock,
                sellStatus: i.sellStatus,
                startTime: i.startTime,
                endTime: i.endTime
              })
            })
            if (result.data.data.length < this.calendarGoodsParam.limit) {
              this.finished = true
            } else {
              this.finished = false
            }
            this.indexLoad = true
          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            this.indexLoad = true
          }
          this.loading = false
          this.$store.commit("HIDE_APPLOADING")
        })
    },
    // 获取系列藏品
    getcollectList () {
      api
        .get('collect/series/list?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
        .then(result => {
          if (result.data.success) {
            this.collectList = result.data.data
            let index = 1
            this.collectList.map(i => {
              if (index < 4 && i.indexFlag == 1) {
                this.selectCollectList.push(i)
                index += 1
              }
            })
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.loading = false
          this.$store.commit("HIDE_APPLOADING")
        })
    },
    // 加载商品下拉loading
    load () {
      if (this.indexGoodMore && !this.finished && this.indexLoad) {
        this.loading = true
        this.calendarGoodsParam.page += 1
        // this.getGoods()
        this.getcollectionList(this.calendarGoodsParam.page)
        this.indexGoodMore = false
      }
    },
    load1 () {
      if (this.indexGoodMore1 && !this.finished1 && this.indexLoad1) {
        this.loading1 = true
        this.blindBoxParam.page += 1
        this.getBlindBoxList()
        this.indexGoodMore1 = false
      }
    },
    // 滑动图片功能
    // touchStart (event) {
    //   this.theTouch = event.touches[0].pageX
    // },
    // touchEnd (event) {
    //   if (event.changedTouches[0].pageX > this.theTouch) {
    //     this.$refs.banner.prev();
    //   } else {
    //     this.$refs.banner.next();
    //   }
    // },
    /**
     * banner详情不显示商品购买信息，数据从localStorage中获取
     * 商品详情信息直接跳转即可
     */
    goDetails (item,isBanner) {
      if (isBanner == 'recommend') {
        this.getBannerDetails(item,isBanner)
      } else {
        this.$router.push('/goodDetails?id=' + item.id + '&isBanner=' + isBanner)
      }
    },
    // 获取banner详情内容
    getBannerDetails (item,isBanner) {
      api
        .get('cms/detail/' + item.id)
        .then(result => {
          if (result.data.success) {
            if (result.data.data.link) {
              window.location.href = result.data.data.link
              // window.open(result.data.data.link);
            } else if (result.data.data.content) {
              localStorage.setItem('titleAndTime',JSON.stringify(item.title + '+/+' + item.createTime))
              localStorage.setItem('recommendDetail',result.data.data.content)
              this.$router.push('/goodDetails?isBanner=' + isBanner)
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取盲盒列表
    getBlindBoxList () {
      this.indexLoad1 = false
      api
        .get('goods?page=' + this.blindBoxParam.page + '&limit=' + this.blindBoxParam.limit + '&skuType=1')
        .then(result => {
          if (result.data.success) {
            result.data.data.map(i => {
              this.blindBoxList.push({
                id: i.id,
                image: i.image,
                name: i.storeName,
                exclusiveBuyType: i.exclusiveBuyType,
                limitInfo: i.limitInfo,
                price: i.price,
                cateId: i.cateId,
                isOpen: i.isOpen,
                stock: i.stock,
                sellStatus: i.sellStatus,
                startTime: i.startTime,
                endTime: i.endTime
              })
            })
            if (result.data.data.length < this.goodsParam.limit) {
              this.finished1 = true
            } else {
              this.finished1 = false
            }
            this.indexLoad1 = true
          } else {
            this.finished1 = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            this.indexLoad1 = true
          }
          this.loading1 = false
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    goswiperDetails (v) {
      let index = v.clickedIndex - v.activeIndex + v.realIndex;
      if (index === this.goods.length) {
        index = 0;
      } else if (index === this.goods.length + 1) {
        index = 1;
      } else if (index === this.goods.length + 2) {
        index = 2;
      }
      // 我得到的index就是点击的item在实际数组中的下标index
      this.goDetails(this.goods[index],'buy')
    },
    //系列查看更多
    goseriesDetails (valule) {
      this.$router.push('/seriesCollectionDetails?id=' + valule.id)
    },
  },
  // 去除监听事件
  beforeDestroy () {
    window.removeEventListener('scroll',this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
.el-menu-demo {
  display: flex;
  justify-content: start;
  .el-menu-demo-item {
    margin: 0 1.25rem 0 3vw;
  }
  .el-menu-item {
    padding-top: 1rem;
    img {
      width: 0.5rem;
      height: auto;
      position: absolute;
      bottom: 0.375rem !important;
      right: -0.3125rem;
    }
  }
}
.order-img-name-img {
  display: flex;
  justify-content: center;
  align-items: center;
  &-inline {
    display: block;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 0;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
}
.mall-container {
  display: flex;
  flex-direction: column;
  background: #1c1c1c;
  margin-bottom: 3.125rem;
  margin-top: 3.875rem;
  height: auto;
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .el-menu-item.is-active {
    font-size: 1.125rem !important;
  }
  .row-right {
    img {
      width: 1rem;
    }
  }
  .row-center-img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-inline {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
  .good-card {
    position: relative;
    width: 94%;
    // margin: 0.75rem 3% 0 3%;
    border-radius: 0.5rem;
    overflow: hidden;
    .good-card-bg {
      height: auto;
      min-height: 6.5rem;
      background-image: url('../assets/icon/goods-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      // background-position: 0% 0%;
      background-position: 60%;
      background-size: cover;
      background-color: transparent;
      border-radius: 0 0 0.5rem 0.5rem;
      padding: 0 1.25rem 0.625rem 1.25rem;
      position: relative;
      .good-name {
        width: 100%;
        font-size: 1.1875rem;
        font-family: lantingheiweight;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.6875rem;
        padding-top: 12px;
        padding-bottom: 2px;
      }

      .good-type {
        position: absolute;
        top: 0.85rem;
        right: 1.3125rem;
        width: 1.25rem;
        height: auto;
      }
      .author-name {
        font-size: 0.75rem;
        font-weight: 500;
        color: #c6c3c2;
        line-height: 1.0625rem;
        margin-left: 0.5625rem;
      }

      .good-prise {
        font-family: SFUIDisplay-Bold, SFUIDisplay;
        font-weight: bold;
        color: #ffffff;
        line-height: 2.625rem;
      }
    }
    .limit-content {
      left: 0;
      margin-top: 0.5rem;
    }
  }
}
.order-item {
  border-radius: 8px;
  background: linear-gradient(
    360deg,
    rgba(250, 232, 220, 0) 70%,
    rgba(243, 205, 182, 0.1) 100%
  );
  width: 95%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.order-content {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.date-type-card {
  height: 2.4375rem;
  .date-type-tip {
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 2.1875rem;
    margin-left: 1.0625rem;
  }
}
.date-type {
  height: 2.6875rem;

  .date-type-tip {
    font-size: 18px;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #ffffff;
    line-height: 2.625rem;
    margin-left: 1rem;
  }
}
.date-img {
  background-image: url('../assets/icon/invitationFriends/background.png');
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.my-swipe {
  width: 100vw;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  width: 100%;
  // margin: 0 4%;
  .good-card {
    margin: 0;
  }
}
.swiper-container {
  height: auto;
  overflow-y: auto;
  // /* 上一张 */
  // .swiper-slide-prev {
  //   margin-right: 7px;
  //   height: auto !important;
  // }
  // /* 下一张 */
  // .swiper-slide-next {
  //   margin-right: 7px;
  //   height: auto !important;
  // }

  /* 中间的图片 */
  .swiper-slide-active {
    // margin-right: 7px;
    height: auto !important;
  }
  .order-menu-index {
    top: 0;
    width: 96vw;
    margin-left: 3vw;
    position: inherit;
    background: transparent !important;
    // .el-menu {
    //   background: transparent !important;
    //   background-color: transparent !important;
    // }
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu-item {
      font-size: 18px;
    }
    .el-menu-item1 {
      margin-right: 50px;
    }
    .el-menu-item2 {
      font-size: 14px;
    }
  }
}
.collection-list {
  width: 94vw;
  margin-left: 3vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  .collection-item-container {
    width: 45.5vw;
  }
  /deep/.collection-item-content {
    width: 45.5vw !important;
    height: calc(45.5vw + 100px) !important;
  }
  /deep/.collection-item-img {
    width: 45.5vw;
    height: 45.5vw;
  }
}
.buy-now {
  height: 2.75rem;
  position: relative;
  right: 0;
  width: 100%;
  margin: 12px 1.3125rem 0.625rem 0;
  background-image: url('../assets/icon/confirm-button.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-color: transparent;
  font-size: 1rem;
  font-family: lantingheiweight;
  font-weight: normal;
  color: #ffffff;
  line-height: 1.375rem;
  letter-spacing: 2px;
  cursor: pointer;
  background-size: cover;
}
.el-index-menu-top {
  position: fixed;
  top: 3.875rem;
  width: 100vw;
  z-index: 202;
  padding-bottom: 5px;
}
.good-detail-type {
  height: 20px;
  width: auto;
}
.el-menu::after,
.el-menu::before {
  display: none;
}
.log-off-tip3 {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 19px;
  width: 80%;
  margin-left: 10%;
}
</style>